import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Logo from "./logo"

const StyledFooter = styled.footer`
  background-color: #eeeeee !important;

  .fa {
    margin-right: 0.5rem;
    color: var(--primary);
  }

  .container {
    justify-content: flex-start;
  }

  .contact {
    text-align: left;
    font-size: 0.8rem;
  }

  .navbar-brand {
    width: 500px;
  }

  a {
    text-decoration: none;
    color: black;

    &:hover {
      color: var(--primary);
    }
  }

  .menu {
    margin: 0 auto;
    a {
      margin-right: 1.5rem;
      font-weight: bold;
      color: black;
      /* padding: 0.5rem;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      border-radius: 0.5rem;
      transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out; */

      &:hover {
        /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        background-color: var(--primary); */
        color: var(--primary);
      }

      .fa {
        margin-right: 0.25rem;
        color: unset;
      }
    }
  }
`

const Footer = () => {
  return (
    <StyledFooter className="navbar navbar-light my-3 d-block d-lg-none">
      <div className="container">
        <div className="contact">
          <div className="phone">
            <a href="tel:+49941307660">
              <i className="fa fa-phone" aria-hidden="true"></i>
              +49 941 30766-0
            </a>
          </div>
          <div className="mail">
            <a href="mailto:h.kraft@spritzgiessfuehrerschein.de">
              <i className="fa fa-envelope" aria-hidden="true"></i>
              h.kraft@spritzgiessfuehrerschein.de
            </a>
          </div>
          <div className="mail">
            <Link to="/impressum">Impressum</Link> | <Link to="/agb">AGB</Link>{" "}
            | <Link to="/datenschutz">Datenschutz</Link>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
